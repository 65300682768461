const slideDown = (el, duration) => {
  el.style.display = 'block'
  el.style.overflow = 'hidden'
  el.style.height = '0px'
  const height = el.scrollHeight
  const step = Math.ceil(height / (duration / 16))
  let currentHeight = 0
  const interval = setInterval(() => {
    currentHeight += step
    if (currentHeight >= height) {
      clearInterval(interval)
      currentHeight = height
      el.style.height = ''
      el.style.overflow = ''
    }else {
      el.style.height = currentHeight + 'px'
      el.classList.add('active')
    }        
  }, 16)
}

const slideClose = (el, duration) => {
  duration = duration - 100
  el.style.overflow = 'hidden'
  const height = el.scrollHeight
  const step = Math.ceil(height / (duration / 16))
  let currentHeight = height
  const interval = setInterval(() => {
    currentHeight -= step
    if (currentHeight <= 0) {
      clearInterval(interval)
      currentHeight = 0
      el.style.display = 'none'
      el.style.overflow = ''
    }else {
      el.style.height = currentHeight + 'px'
      el.classList.remove('active')
    }
    
  }, 16)
}

const slideToggle = (id, duration = 500) => {
  
  const container = document.getElementById(id)
  console.log('container', container)
  if(!container) return
  
  const isOpen = container.classList.contains('active')
  
  if(isOpen) {
    slideClose(container, duration)
  }else {
    slideDown(container, duration)
  }
  
}

export { slideToggle }