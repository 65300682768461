function initVideos () {

  const videoTriggers = document.querySelectorAll('.video-block-trigger')

  videoTriggers.forEach(videoTrigger => {

    videoTrigger.onclick = (e) => {
      videoTrigger.classList.add('hidden')
      videoTrigger.nextElementSibling.classList.remove('hidden')

      const iframe = videoTrigger.nextElementSibling

      iframe.classList.remove('hidden')
      iframe.src = iframe.src.replace(/autoplay\=0/i, 'autoplay=1')
    }

  })

}

export { initVideos }