const toggleNavMenuButton = document.getElementById('toggle-nav-menu')
const navMenu = document.getElementById('nav-menu')
toggleNavMenuButton.onclick = (e) => {
  e.preventDefault()

  const expanded = toggleNavMenuButton.getAttribute('aria-expanded')
  if( expanded == 'false' ){
    toggleNavMenuButton.setAttribute('aria-expanded', 'true')
    navMenu.classList.remove('-translate-y-full')
  }else{
    toggleNavMenuButton.setAttribute('aria-expanded', 'false')
    navMenu.classList.add('-translate-y-full')
  }
}




const hideDropdownGroup = (dropdownGroup) => {
  dropdownGroup.classList.remove('active')
}

const showDropdownGroup = (dropdownGroup) => {
  dropdownGroup.classList.add('active')
}

let dropDownToggles = []

const initDropdownToggles = () => {
  dropDownToggles = document.querySelectorAll('.dropdown-toggle')

  const changeActive = (dropdownGroup) => {
    if(dropdownGroup.classList.contains('active')){
      hideDropdownGroup(dropdownGroup)
    }else{
      showDropdownGroup(dropdownGroup)
    }
  }

  dropDownToggles.forEach(dropDownToggle => {

    const dropdownGroup = dropDownToggle.parentNode

    dropDownToggle.onclick = (e) => {
      if(window.innerWidth > 1023) return

      e.preventDefault()

      changeActive(dropdownGroup)
    }

    dropdownGroup.onmouseover = () => {
      if(window.innerWidth < 1024) return
      showDropdownGroup(dropdownGroup)
    }

    dropdownGroup.onmouseout = () => {
      if(window.innerWidth < 1024) return
      hideDropdownGroup(dropdownGroup)
    }

  })

}

initDropdownToggles()

window.addEventListener('resize', () => {
  dropDownToggles.forEach(dropDownToggle => {
    const dropdownGroup = dropDownToggle.parentNode
    hideDropdownGroup(dropdownGroup)
  })
})


const modalTriggers = document.querySelectorAll('.modal-trigger')

const openModal = (modalTarget, modalBackDrop) => {
  document.body.classList.add('overflow-y-hidden')
    
  modalTarget.style.setProperty('opacity', '1')
  modalTarget.style.setProperty('visibility', 'visible')

  modalBackDrop.style.setProperty('opacity', '1')
  modalBackDrop.style.setProperty('visibility', 'visible')
}

const closeModal = (modalTarget, modalBackDrop) => {
  modalTarget.style.setProperty('opacity', '0')
  modalTarget.style.setProperty('visibility', 'hidden')

  modalBackDrop.style.setProperty('opacity', '0')
  modalBackDrop.style.setProperty('visibility', 'hidden')
  
  document.body.classList.remove('overflow-y-hidden')
}

modalTriggers.forEach(modalTrigger => {
  modalTrigger.onclick = (e) => {
    e.preventDefault()

    const target = modalTrigger.dataset.targetId

    const modalTarget = document.getElementById(target)
    const modalBackDrop = modalTarget.querySelector('.modal-backdrop')
    const modalClose = modalTarget.querySelector('.modal-close')

    openModal(modalTarget, modalBackDrop)

    modalClose.onclick = (e) => {
      e.preventDefault()

      closeModal(modalTarget, modalBackDrop)
    }

    modalBackDrop.onclick = (e) => {
      e.preventDefault()
      closeModal(modalTarget, modalBackDrop)
    }

  }
})


