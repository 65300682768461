import Swiper, { Pagination, Autoplay, Scrollbar, Navigation, Thumbs, Zoom } from 'swiper'

function commonSwiper(swiperContainerIdentifier = '.common-swiper', additionalOptions = {}, enableNav = false){
  const paginationElementIdentifier = `${swiperContainerIdentifier}-pagination`

  let modules = [
    Pagination
  ];

  let navigationParam = {}

  if(enableNav){
    modules.push(Navigation)

    navigationParam = {
      prevEl: `${swiperContainerIdentifier}-nav-prev`,
      nextEl: `${swiperContainerIdentifier}-nav-next`,
    }
  }

  if(additionalOptions.hasOwnProperty('autoplay')){
    modules.push(Autoplay)
  }

  if(additionalOptions.hasOwnProperty('scrollbar')){
    modules.push(Scrollbar)
  }
  
  if(additionalOptions.hasOwnProperty('thumbs')){
    modules.push(Thumbs)
  }

  if(additionalOptions.hasOwnProperty('zoom')){
    modules.push(Zoom)
  }

  return new Swiper(swiperContainerIdentifier, {

    modules: modules,
  
    pagination: {
      el: paginationElementIdentifier,
      clickable: true,
    },

    navigation: navigationParam,

    ...additionalOptions
  
  })

}

export { commonSwiper }